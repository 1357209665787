import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { BookSale } from '../../models/index.js';

export enum VISIBILITY_FILTER {
  SHOW_ALL = 'SHOW_ALL',
}

export interface BookSalesState extends EntityState<BookSale>, ActiveState {
  ui: {
    filter: VISIBILITY_FILTER;
  };
}
const initialState = {
  ui: {
    filter: VISIBILITY_FILTER.SHOW_ALL,
  },
};

// export interface BookSaleUI {}

// export interface BookSalesUIState extends EntityState<BookSaleUI>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'book-sales', resettable: true, idKey: 'bookSaleId' })
export class BookSalesStore extends EntityStore<BookSalesState> {
  // ui: EntityUIStore<BookSalesUIState>;
  constructor() {
    super();
    // this.createUIStore().setInitialEntityState();
  }
}
