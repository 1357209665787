import { filter } from '@angular-devkit/schematics';
import { CurrentUserFacade } from '@awread/core/users';
import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, tap } from 'rxjs';
import { format, formatRelative } from 'date-fns';
import { vi } from 'date-fns/locale';

@Injectable({ providedIn: 'root' })
export class CartsApi {
  constructor(
    private apollo: Apollo,
    private currentUserFacade: CurrentUserFacade,
  ) { }

  getOneCart(bookId: string) {
    return this.apollo
      .query<any>({
        query: gql`
          query oneCart($bookId: UUID!, $userId: UUID!) {
            cart(bookId: $bookId, userId: $userId) {
              bookId
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
          bookId,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result: any) => result?.['data']?.['cart']));
  }

  getAllCarts() {
    return this.apollo
      .query<any>({
        query: gql`
          query allCarts($userId: UUID!) {
            carts(orderBy: CREATED_AT_DESC, condition: { userId: $userId }) {
              nodes {
                bookId
                createdAt
                userId
                book {
                  bookId
                  bookNid
                  title
                  userId
                  cover
                  description
                  user {
                    name
                    userId
                  }
                  booksPrices {
                    nodes {
                      priceId
                      discountId
                      discount {
                        percent
                        endDate
                      }
                      price {
                        price
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        map((result: any) => result?.['data']?.['carts']?.['nodes']),
        map((carts: any) =>
          carts.map((cart: any) => ({
            createdAt: cart.createdAt,
            title: cart.book.title,
            cover: cart.book.cover,
            authorName: cart.book.user.name,
            userId: cart.book.user.userId,
            percent:
              cart?.book?.booksPrices?.nodes[0]?.discount?.percent &&
                new Date(Date.now()).getTime() < new Date(cart?.book?.booksPrices?.nodes[0]?.discount?.endDate).getTime()
                ? cart?.book?.booksPrices?.nodes[0]?.discount?.percent / 100
                : 0,
            price: cart?.book?.booksPrices?.nodes[0]?.price?.price,
            bookId: cart.bookId,
            bookNid: cart.book.bookNid,
          })),
        ),
        // tap((carts = []) =>
        //   carts.forEach((cart: any) => {
        //     totalPrice += Number(cart?.book?.booksPrices?.nodes[0]?.price?.price);
        //     if (
        //       cart?.book?.booksPrices?.nodes[0]?.discount?.percent &&
        //       new Date(Date.now()).getTime() < new Date(cart?.book?.booksPrices?.nodes[0]?.discount?.endDate).getTime()
        //     ) {
        //       totalPriceDiscount += (Number(cart?.book?.booksPrices?.nodes[0]?.price?.price) * cart?.book?.booksPrices?.nodes[0]?.discount?.percent) / 100;
        //     }
        //   })
        // ),
        // map((carts = []) =>
        //   carts.map((cart: any) => ({
        //     ...cart,
        //     price: Number(cart?.book?.booksPrices?.nodes[0]?.price?.price),
        //     percent:
        //       cart?.book?.booksPrices?.nodes[0]?.discount?.percent &&
        //       new Date(Date.now()).getTime() < new Date(cart?.book?.booksPrices?.nodes[0]?.discount?.endDate).getTime()
        //         ? cart?.book?.booksPrices?.nodes[0]?.discount?.percent / 100
        //         : 0,
        //     totalPrice: totalPrice,
        //     totalPriceDiscount: totalPriceDiscount,
        //   }))
        // )
      );
  }

  createCart(bookId: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation createCart($userId: UUID!, $bookId: UUID!) {
            createCart(input: { cart: { userId: $userId, bookId: $bookId } }) {
              cart {
                bookId
                createdAt
                userId
              }
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
          bookId,
        },
      })
      .pipe(map((result: any) => result?.['data']?.['createCart']?.['cart']));
  }

  deleteCart(bookId: any) {
    return this.apollo
      .mutate<any>({
        mutation: gql`
          mutation deleteCart($bookId: UUID!, $userId: UUID!) {
            deleteCart(input: { userId: $userId, bookId: $bookId }) {
              cart {
                bookId
                userId
              }
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
          bookId,
        },
      })
      .pipe(map((result: any) => result?.['data']?.['deleteCart']?.['cart']));
  }

  // buyBook() {
  //   return this.apollo
  //     .mutate<any>({
  //       mutation: gql`
  //         mutation buyBook($clientMutationId: String) {
  //           buyBook(input: { clientMutationId: $clientMutationId }) {
  //             clientMutationId
  //             string
  //           }
  //         }
  //       `,
  //       variables: {},
  //     })
  //     .pipe(map((result: any) => result?.['data']?.['buyBook']));
  // }

  getAllBookSalesOfReader() {
    return this.apollo
      .query<any>({
        query: gql`
          query getAllBookSalesOfReader($userId: UUID!) {
            bookSales(orderBy: TIMESTAMP_DESC, condition: { userId: $userId }) {
              nodes {
                userId
                bookId
                timestamp
                value
                discount
                price
                saleTransactionId
                balance
                bookSaleId

                book {
                  title
                  user {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
        },
      })
      .pipe(
        map((result: any) => result?.['data']?.['bookSales']?.['nodes']),
        map((res) =>
          res.map((item: any) => ({
            ...item,
            coin: Math.floor(Number(item.value)).toLocaleString('it-IT'),
          })),
        ),
      );
  }

  getAllBookSalesOfWriter() {
    return this.apollo
      .query<any>({
        query: gql`
          query getAllBookSalesOfWriter($salerId: UUID!) {
            bookSales(orderBy: TIMESTAMP_DESC, condition: { salerId: $salerId }) {
              nodes {
                bookSaleId
                timestamp
                value
                discount
                price
                balance
                salerId
                book {
                  userId
                  title
                  user {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: {
          salerId: this.currentUserFacade.getUserId(),
        },
      })
      .pipe(
        map((result: any) => result?.['data']?.['bookSales']?.['nodes']),
        map((res) =>
          res.map((item: any) => ({
            ...item,
            profit: new Date(item.timestamp) >= new Date('2023-10-20') ? item.value : item.value * 0.7,
          })),
        ),
      );
  }

  getBookSalesByMonth() {
    return this.apollo
      .query<any>({
        query: gql`
          query allBookSalesByMonth($salerId: UUID!) {
            bookSales(orderBy: TIMESTAMP_DESC, condition: { salerId: $salerId }) {
              nodes {
                userId
                bookId
                timestamp
                value
                discount
                price
                saleTransactionId
                balance
                bookSaleId
                book {
                  userId
                  title
                  user {
                    name
                  }
                }
              }
            }
          }
        `,
        variables: {
          salerId: this.currentUserFacade.getUserId(),
        },
      })
      .pipe(
        map(
          (result: any) =>
            result?.['data']?.['bookSales']?.['nodes'].filter(
              (res: any) =>
                new Date(res.timestamp).getMonth() === new Date(Date.now()).getMonth() &&
                new Date(res.timestamp).getFullYear() === new Date(Date.now()).getFullYear(),
            ),
        ),
        map((res) =>
          res.map((item: any) => ({
            ...item,
            profit: new Date(item.timestamp) >= new Date('2023-10-20') ? item.value : item.value * 0.7,
          })),
        ),
      );
  }

  getVault() {
    return this.apollo
      .query<any>({
        query: gql`
          query vault($userId: UUID!) {
            vault(userId: $userId) {
              balance
              userId
            }
          }
        `,
        variables: {
          userId: this.currentUserFacade.getUserId(),
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map((result: any) => result?.['data']?.['vault']));
  }
}
