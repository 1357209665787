import { BookSalesStore } from './../states/book-sales/book-sales.store.js';
import { CartsStore } from './../states/carts/carts.store.js';
import { CartsApi } from './../apis/carts.api.js';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { LibraryBooksFacade } from '@awread/core/library-books';

@Injectable({ providedIn: 'root' })
export class CartsGear {
  constructor(
    private cartsApi: CartsApi,
    private cartsStore: CartsStore,
    private bookSalesStore: BookSalesStore,
    private libraryBooksFacade: LibraryBooksFacade,
  ) { }

  getAllCarts() {
    return this.cartsApi.getAllCarts().pipe(tap((res) => this.cartsStore.set(res)));
  }

  getOneCart(bookId: string) {
    return this.cartsApi.getOneCart(bookId);
  }

  createCart(book: any) {
    return this.cartsApi.createCart(book.bookId).pipe(
      tap((cart: any) => {
        this.cartsStore.add({
          title: book.title,
          createdAt: cart.createdAt,
          cover: book.cover,
          authorName: book.name,
          percent: book.percent && new Date(Date.now()).getTime() < new Date(book.endDate).getTime() ? book.percent / 100 : 0,
          price: book.price,
          bookId: book.bookId,
          userId: book.userId,
          bookNid: book.bookNid,
        });
      }),
    );
  }

  deleteCart(bookId: any) {
    return this.cartsApi.deleteCart(bookId).pipe(tap(() => this.cartsStore.remove(bookId)));
  }

  // buyBook() {
  //   return this.cartsApi.buyBook().pipe(
  //     tap((result: any) => {
  //       if (result.string === 'done') {
  //         this.cartsStore.reset();
  //         this.libraryBooksFacade.getAllLibraryBooks().subscribe();
  //       }
  //     }),
  //   );
  // }

  getAllBookSales() {
    return this.cartsApi.getAllBookSalesOfReader().pipe(tap((res) => this.bookSalesStore.set(res)));
  }

  getAllBookSalesOfWriter() {
    return this.cartsApi.getAllBookSalesOfWriter().pipe(tap((res) => this.bookSalesStore.set(res)));
  }

  getBookSalesByMonth(earningMonth: number) {
    return this.cartsApi.getBookSalesByMonth().pipe(
      tap((vaults) => {
        for (let i = 0; i < vaults.length; i++) {
          earningMonth += vaults[i]?.profit;
        }
      }),
      map((vaults = []) =>
        vaults.map((vault: any) => ({
          ...vault,
          earningMonth: earningMonth,
        })),
      ),
    );
  }

  getVault() {
    return this.cartsApi.getVault();
  }
}
