import { Injectable } from '@angular/core';
import { Order, QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';
import { CartsStore, CartsState } from './carts.store.js';
// import { CartsStore, CartsState, CartsUIState } from './carts.store';

@Injectable({ providedIn: 'root' })
export class CartsQuery extends QueryEntity<CartsState> {
  carts$ = this.selectAll({
    sortBy: 'createdAt',
    sortByOrder: Order.DESC,
  });
  // ui!: EntityUIQuery<CartsUIState>;
  constructor(protected override store: CartsStore) {
    super(store);
    // this.createUIQuery();
  }

  selectTotalMoney() {
    return this.selectAll().pipe(
      map((carts: any) => {
        carts = carts.filter((cartItem: any) => cartItem.price);
        return {
          totalItem: carts.length,
          totalDiscount: carts.reduce((totalDiscount: number, cartItem: any) => {
            return totalDiscount + cartItem.price * cartItem.percent;
          }, 0),
          totalPrice: carts.reduce((totalPrice: number, cartItem: any) => {
            return totalPrice + +cartItem.price;
          }, 0),
          totalValue: carts.reduce((totalMoney: number, cartItem: any) => {
            return totalMoney + (cartItem.price - cartItem.price * cartItem.percent);
          }, 0),
        };
      })
    );
  }
}
