import { BookSalesQuery } from './../states/book-sales/book-sales.query.js';
import { CartsQuery } from './../states/carts/carts.query.js';
import { CartsGear } from './../gears/carts.gear.js';
import { Injectable, inject } from '@angular/core';
import { CartsStore } from '../states/carts/index.js';

@Injectable({ providedIn: 'root' })
export class CartsFacade {
  cartsQuery = inject(CartsQuery);
  bookSalesQuery = inject(BookSalesQuery);

  carts$ = this.cartsQuery.carts$;
  totalMoney$ = this.cartsQuery.selectTotalMoney();
  bookSales$ = this.bookSalesQuery.selectAll();
  bookSalesWriter$ = this.bookSalesQuery.selectAll();

  constructor(
    private cartsGear: CartsGear,
    public cartsStore: CartsStore,
  ) { }

  getAllCarts() {
    return this.cartsGear.getAllCarts();
  }

  getOneCart(bookId: string) {
    return this.cartsGear.getOneCart(bookId);
  }

  createCart(bookId: any) {
    return this.cartsGear.createCart(bookId);
  }

  deleteCart(bookId: any) {
    return this.cartsGear.deleteCart(bookId);
  }

  // buyBook() {
  //   return this.cartsGear.buyBook();
  // }

  getAllBookSales() {
    return this.cartsGear.getAllBookSales();
  }

  getAllBookSalesWriter() {
    return this.cartsGear.getAllBookSalesOfWriter();
  }

  getBookSalesByMonth(earningMonth: number) {
    return this.cartsGear.getBookSalesByMonth(earningMonth);
  }

  getVault() {
    return this.cartsGear.getVault();
  }
}
