import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig, ActiveState } from '@datorama/akita';
import { Cart } from '../../models/index.js';

export enum VISIBILITY_FILTER {
  SHOW_ALL = 'SHOW_ALL',
}

export interface CartsState extends EntityState<Cart>, ActiveState {
  ui: {
    filter: VISIBILITY_FILTER;
  };
}
const initialState = {
  ui: {
    filter: VISIBILITY_FILTER.SHOW_ALL,
  },
};

// export interface CartUI {}

// export interface CartsUIState extends EntityState<CartUI>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'carts', resettable: true, idKey: 'bookId' })
export class CartsStore extends EntityStore<CartsState> {
  // ui: EntityUIStore<CartsUIState>;
  constructor() {
    super();
    // this.createUIStore().setInitialEntityState();
  }
}
