import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { BookSalesStore, BookSalesState } from './book-sales.store.js';
// import { BookSalesStore, BookSalesState, BookSalesUIState } from './book-sales.store';

@Injectable({ providedIn: 'root' })
export class BookSalesQuery extends QueryEntity<BookSalesState> {
  // ui!: EntityUIQuery<BookSalesUIState>;
  constructor(protected override store: BookSalesStore) {
    super(store);
    // this.createUIQuery();
  }
}
